var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _ from "underscore";
import { until } from "@vueuse/core";
import { dataURItoBlob, isDataUri } from "src/shared/data-uri";
import { batchUpdateCreativeVariables, replaceCampaignLocaleImage, replaceLocaleImage, saveCampaignVariableUpdates, } from "./operations";
import fetchVariables from "./fetch-variables";
import { useCreativeVariableStore } from "src/shared/store/creative-variable";
import { calculateVariantsFromLegacyCreativeVariableValues } from "src/shared/store/campaign-built/variants";
import { useCampaignRootBuiltComponent } from "src/shared/store/component-built/composables";
import { useCampaignComponentVariableStore } from "src/shared/store/campaign-component/variable/index";
import CampaignComponentVariableUpdateMutable from "src/shared/store/campaign-component/variable/model/CampaignComponentVariableUpdateMutable";
import { useCampaignComponentVariable } from "src/shared/store/campaign-component/variable/composables";
import { campaignChangeEvent } from "../../event-bus/campaign";
export const LOCALE_VARIABLES = ["defaultLocale", "enabledLocales"];
const isImageVariable = (updateRequest) => {
    return updateRequest.type === "IMAGE";
};
const convertCreativeImageUpdateToRequests = (creativeActionId, variableUpdate) => {
    const output = [];
    if (variableUpdate.type === "IMAGE") {
        _.each(variableUpdate.values, (value, key) => {
            if (isDataUri(value)) {
                const data = new FormData();
                data.append("file", dataURItoBlob(value), variableUpdate.fileName);
                output.push({
                    creativeActionId: creativeActionId,
                    name: variableUpdate.name,
                    locale: key,
                    image: data,
                });
            }
        });
    }
    return output;
};
const convertCampaignImageUpdateToRequests = (variableUpdate) => {
    const output = [];
    if (variableUpdate.type === "IMAGE") {
        _.each(variableUpdate.values, (value, key) => {
            if (isDataUri(value)) {
                const data = new FormData();
                data.append("file", dataURItoBlob(value), variableUpdate.fileName);
                output.push({
                    name: variableUpdate.name,
                    locale: key,
                    image: data,
                });
            }
        });
    }
    return output;
};
const partitionCreativeVariableUpdates = (creativeActionId, variableUpdates) => {
    const output = {
        variableUpdates: [],
        imageVariableUpdates: [],
    };
    const [imageVariableUpdates, nonImageVariableUpdates] = _.partition(variableUpdates, isImageVariable);
    _.each(imageVariableUpdates, (update) => {
        const values = {};
        _.each(update.values, (value, key) => {
            if (!isDataUri(value)) {
                values[key] = value;
            }
        });
        output.variableUpdates.push({
            creative_action_id: creativeActionId,
            name: update.name,
            scope: update.scope,
            values: values,
        });
        _.each(convertCreativeImageUpdateToRequests(creativeActionId, update), (request) => {
            output.imageVariableUpdates.push(request);
        });
    });
    _.each(nonImageVariableUpdates, (update) => {
        output.variableUpdates.push({
            creative_action_id: creativeActionId,
            name: update.name,
            values: update.values,
            scope: update.scope,
        });
    });
    return output;
};
const partitionCampaignVariableUpdates = (variableUpdates) => {
    const output = {
        variableUpdates: [],
        imageVariableUpdates: [],
    };
    const [imageVariableUpdates, nonImageVariableUpdates] = _.partition(variableUpdates, isImageVariable);
    _.each(imageVariableUpdates, (update) => {
        _.each(convertCampaignImageUpdateToRequests(update), (request) => {
            output.imageVariableUpdates.push(request);
        });
    });
    output.variableUpdates = nonImageVariableUpdates;
    return output;
};
const batchSaveCreativeArchiveVariables = (campaignId, updatesByCreativeActionId) => __awaiter(void 0, void 0, void 0, function* () {
    const batchVariableUpdateRequests = [];
    const campaignLocaleImageUpdateRequests = [];
    _.each(updatesByCreativeActionId, (updates, creativeActionId) => {
        const partitioned = partitionCreativeVariableUpdates(creativeActionId, updates);
        _.each(partitioned.variableUpdates, (update) => batchVariableUpdateRequests.push(update));
        _.each(partitioned.imageVariableUpdates, (update) => campaignLocaleImageUpdateRequests.push(update));
    });
    if (batchVariableUpdateRequests.length) {
        yield batchUpdateCreativeVariables(campaignId, batchVariableUpdateRequests);
    }
    for (const request of campaignLocaleImageUpdateRequests) {
        yield replaceLocaleImage(campaignId, request.creativeActionId, request.name, request.locale, request.image);
    }
});
const updateRootComponentVariantsVariable = (campaignId, variants) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    try {
        const { campaignRootBuiltComponent } = useCampaignRootBuiltComponent(campaignId);
        if (campaignRootBuiltComponent.value) {
            const { campaignComponentVariable, primer } = useCampaignComponentVariable(campaignId, campaignRootBuiltComponent.value.id, "variants");
            const campaignComponentVariableStore = useCampaignComponentVariableStore();
            yield until(() => primer.isReady).toBe(true);
            if (campaignComponentVariable.value) {
                const mutable = new CampaignComponentVariableUpdateMutable(campaignComponentVariable.value);
                if (!mutable.values) {
                    return;
                }
                mutable.values.default = variants;
                mutable.source = "LOCAL";
                yield campaignComponentVariableStore.updateVariableV2((_a = campaignComponentVariable.value) === null || _a === void 0 ? void 0 : _a.context.campaignId, (_b = campaignComponentVariable.value) === null || _b === void 0 ? void 0 : _b.context.componentId, (_c = campaignComponentVariable.value) === null || _c === void 0 ? void 0 : _c.name, mutable);
            }
        }
    }
    catch (e) {
        console.error(e);
    }
});
const parsedLocaleVariableUpdates = (update, campaignId) => {
    const creativeVariableStore = useCreativeVariableStore();
    const defaultLocaleVariable = creativeVariableStore.getCampaignVariable(campaignId, "defaultLocale");
    const enabledLocalesVariable = creativeVariableStore.getCampaignVariable(campaignId, "enabledLocales");
    const defaultLocale = _.findWhere(update, { name: "defaultLocale" }) ||
        defaultLocaleVariable ||
        null;
    const enabledLocales = _.findWhere(update, { name: "enabledLocales" }) ||
        enabledLocalesVariable ||
        null;
    return {
        defaultLocale,
        enabledLocales,
    };
};
const getLocaleVariableUpdates = (partitionedVariableUpdates) => {
    return _.filter(partitionedVariableUpdates.variableUpdates, (variable) => LOCALE_VARIABLES.includes(variable.name));
};
const batchSaveCampaignVariables = (campaignId, updates) => __awaiter(void 0, void 0, void 0, function* () {
    const partitioned = partitionCampaignVariableUpdates(updates);
    if (partitioned.variableUpdates.length) {
        const localeVariableUpdates = getLocaleVariableUpdates(partitioned);
        if (!_.isEmpty(localeVariableUpdates) && localeVariableUpdates) {
            const variants = calculateVariantsFromLegacyCreativeVariableValues(parsedLocaleVariableUpdates(localeVariableUpdates, campaignId));
            yield updateRootComponentVariantsVariable(campaignId, variants);
        }
        yield saveCampaignVariableUpdates(campaignId, partitioned.variableUpdates);
    }
    for (const request of partitioned.imageVariableUpdates) {
        yield replaceCampaignLocaleImage(campaignId, request);
    }
});
const matchingUpdateExists = (updates, variable) => {
    return Boolean(_.find(updates, (update) => {
        return update.name === variable.name;
    }));
};
const wasVariableAffected = (updates, variable) => {
    const matchingUpdateExistsAtCampaignLevel = matchingUpdateExists(updates.campaignVariables, variable);
    if (variable.context.name === "CAMPAIGN") {
        return matchingUpdateExistsAtCampaignLevel;
    }
    const matchingUpdateExistsAtCreativeLevel = matchingUpdateExists(updates.creativeVariables[variable.creative_action_id], variable);
    if (variable.scope === "CAMPAIGN") {
        return (matchingUpdateExistsAtCampaignLevel ||
            matchingUpdateExistsAtCreativeLevel);
    }
    return matchingUpdateExistsAtCreativeLevel;
};
const getVariablesThatWereUpdated = (campaignId, updates) => __awaiter(void 0, void 0, void 0, function* () {
    const allVariables = yield fetchVariables(campaignId);
    const variablesThatWereAffected = {
        campaignVariables: {},
        creativeVariables: {},
    };
    _.each(allVariables.campaignVariables, (variable) => {
        if (wasVariableAffected(updates, variable)) {
            variablesThatWereAffected.campaignVariables[variable.name] =
                variable;
        }
    });
    _.each(allVariables.creativeVariables, (variables, creativeId) => {
        _.each(variables, (variable) => {
            if (wasVariableAffected(updates, variable)) {
                variablesThatWereAffected.creativeVariables[creativeId] =
                    variablesThatWereAffected.creativeVariables[creativeId] ||
                        {};
                variablesThatWereAffected.creativeVariables[creativeId][variable.name] = variable;
            }
        });
    });
    return variablesThatWereAffected;
});
const saveVariables = (campaignId, updates) => __awaiter(void 0, void 0, void 0, function* () {
    yield batchSaveCreativeArchiveVariables(campaignId, updates.creativeVariables);
    yield batchSaveCampaignVariables(campaignId, updates.campaignVariables);
    campaignChangeEvent.emit({
        campaignId,
    });
    return yield getVariablesThatWereUpdated(campaignId, updates);
});
export default saveVariables;
