import _ from "underscore";
export const toList = (variableSet) => {
    const list = [];
    _.each(variableSet.campaignVariables, (variable) => list.push(variable));
    _.each(variableSet.creativeVariables, (variables) => {
        _.each(variables, (variable) => list.push(variable));
    });
    return list;
};
export const filter = (variables, predicate) => {
    const filtered = {
        campaignVariables: {},
        creativeVariables: {},
    };
    _.each(variables.campaignVariables, (variable) => {
        if (predicate(variable)) {
            filtered.campaignVariables[variable.name] = variable;
        }
    });
    _.each(variables.creativeVariables, (variables, zoneName) => {
        _.each(variables, (variable) => {
            if (predicate(variable)) {
                filtered.creativeVariables[zoneName] =
                    filtered.creativeVariables[zoneName] || {};
                filtered.creativeVariables[zoneName][variable.name] = variable;
            }
        });
    });
    return filtered;
};
