var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _ from "underscore";
import CampaignEndpoints from "src/shared/api/campaign/CampaignEndpoints";
import CreativeVariableEndpoints from "src/shared/api/creative/CreativeVariableEndpoints";
import { createVariableSet } from "./variable-set-factory";
const CAMPAIGN_IMAGE = "campaignImage";
const fetchLatestCampaignVersion = (campaignId) => {
    return new Promise((resolve, reject) => {
        CampaignEndpoints.getBuiltCampaign(campaignId)
            .then((campaignResponse) => {
            resolve(String(campaignResponse.version));
        })
            .fail(() => reject());
    });
};
const fetchCampaignVariables = (campaignId) => {
    return new Promise((resolve, reject) => {
        CreativeVariableEndpoints.getCampaignVariables(campaignId)
            .then((creativeVariableResponseSet) => {
            resolve(_.filter(creativeVariableResponseSet, (creativeVariableResponse) => !creativeVariableResponse.name.startsWith(CAMPAIGN_IMAGE)));
        })
            .fail(() => reject());
    });
};
const fetchCreativeVariables = (campaignId) => {
    return new Promise((resolve, reject) => {
        CreativeVariableEndpoints.batchGetVariables(campaignId)
            .then((creativeVariableResponseSetsByCreativeId) => {
            resolve(creativeVariableResponseSetsByCreativeId);
        })
            .fail(() => reject());
    });
};
const fetchVariables = (campaignId) => __awaiter(void 0, void 0, void 0, function* () {
    const [latestCampaignVersion, campaignVariableResponses, creativeVariableResponses,] = yield Promise.all([
        fetchLatestCampaignVersion(campaignId),
        fetchCampaignVariables(campaignId),
        fetchCreativeVariables(campaignId),
    ]);
    return createVariableSet(campaignId, latestCampaignVersion, campaignVariableResponses, creativeVariableResponses);
});
export default fetchVariables;
