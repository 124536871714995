var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineStore } from "pinia";
import _ from "underscore";
import { reactive } from "vue";
import CreativeVariableMutable from "./model/CreativeVariableMutable";
import variableService from "./variable-service";
import { campaignModuleEventBus, EVENT } from "../modules/campaign/event-bus";
import { filter, toList } from "./variable-service/operations/filter";
import Exception from "../common/Exception";
export const getVariablesWithConflicts = (variables) => {
    return filter(variables, (variable) => variable.hasConflicts());
};
const hasConflicts = (variables) => {
    return toList(getVariablesWithConflicts(variables)).length > 0;
};
const createOrUpdateMutable = (mutables, variable) => {
    const existingMutable = mutables[variable.name];
    if (!existingMutable) {
        mutables[variable.name] = new CreativeVariableMutable(variable);
    }
    else {
        existingMutable.updateSource(variable);
    }
};
const createOrUpdateMutables = (mutables, variables) => {
    _.each(variables.campaignVariables, (variable) => {
        createOrUpdateMutable(mutables.campaignVariables, variable);
    });
    _.each(variables.creativeVariables, (creativeVariables, creativeActionId) => {
        if (!mutables.creativeVariables[creativeActionId]) {
            mutables.creativeVariables[creativeActionId] = {};
        }
        _.each(creativeVariables, (variable) => {
            createOrUpdateMutable(mutables.creativeVariables[creativeActionId], variable);
        });
    });
};
const toUpdates = (variables) => {
    return _.chain(_.values(variables))
        .map((variable) => {
        return {
            name: variable.name,
            type: variable.type,
            scope: variable.scope,
            values: variable.values,
            fileName: variable.fileName,
            creative_action_id: variable.creativeActionId,
        };
    })
        .value();
};
const shouldSave = (variable) => {
    return variable.isDirty() && !variable.isSaving;
};
const getVariablesToSave = (variables) => {
    const variablesToSave = {
        campaignVariables: {},
        creativeVariables: {},
    };
    _.each(variables.campaignVariables, (variable) => {
        if (shouldSave(variable)) {
            variablesToSave.campaignVariables[variable.name] = variable;
        }
    });
    _.each(variables.creativeVariables, (variables, creativeActionId) => {
        _.each(variables, (variable) => {
            if (shouldSave(variable)) {
                variablesToSave.creativeVariables[creativeActionId] =
                    variablesToSave.creativeVariables[creativeActionId] || {};
                variablesToSave.creativeVariables[creativeActionId][variable.name] = variable;
            }
        });
    });
    return variablesToSave;
};
const convertToUpdateSet = (variables) => {
    const updates = {
        campaignVariables: [],
        creativeVariables: {},
    };
    updates.campaignVariables = toUpdates(variables.campaignVariables);
    _.each(variables.creativeVariables, (variables, creativeActionId) => {
        updates.creativeVariables[creativeActionId] = toUpdates(variables);
        _.each(updates.creativeVariables[creativeActionId], (update) => (update.scope = "CREATIVE"));
    });
    return updates;
};
export const useCreativeVariableStore = defineStore("creativeVariableStore", () => {
    const state = reactive({});
    const provideVariableSet = _.memoize((campaignId) => {
        state[campaignId] = {
            campaignVariables: {},
            creativeVariables: {},
        };
        return state[campaignId];
    });
    const setVariables = (campaignId, variables) => {
        const mutables = provideVariableSet(campaignId);
        createOrUpdateMutables(mutables, variables);
    };
    const fetchVariables = (campaignId) => __awaiter(void 0, void 0, void 0, function* () {
        setVariables(campaignId, yield variableService.fetchVariables(campaignId));
    });
    const doSave = (campaignId, variables) => __awaiter(void 0, void 0, void 0, function* () {
        const updates = convertToUpdateSet(variables);
        const save = variableService.saveVariables(campaignId, updates);
        bindSavingStatus(variables, save);
        const updatedVariables = yield save;
        setVariables(campaignId, updatedVariables);
    });
    const saveVariables = (campaignId) => __awaiter(void 0, void 0, void 0, function* () {
        yield fetchVariables(campaignId);
        const variables = provideVariableSet(campaignId);
        if (hasConflicts(variables)) {
            throw new Exception("unresolved_variable_conflicts", "Cannot save variables with unresolved conflicts");
        }
        const variablesToSave = getVariablesToSave(variables);
        return doSave(campaignId, variablesToSave);
    });
    const saveVariable = (variable) => __awaiter(void 0, void 0, void 0, function* () {
        const { campaignId } = variable.context;
        const variableSet = {
            campaignVariables: {},
            creativeVariables: {},
        };
        if (variable.context.name === "CAMPAIGN") {
            variableSet.campaignVariables[variable.name] = variable;
        }
        else {
            variableSet.creativeVariables[variable.creativeActionId] = {
                [variable.name]: variable,
            };
        }
        return doSave(campaignId, variableSet);
    });
    const getVariables = (campaignId) => {
        return provideVariableSet(campaignId);
    };
    const getCampaignVariables = (campaignId) => {
        const variableMutableSet = provideVariableSet(campaignId);
        return _.values(variableMutableSet.campaignVariables || []);
    };
    const getCreativeVariables = (campaignId, creativeActionId) => {
        if (!creativeActionId) {
            return [];
        }
        const variablesByCreativeActionId = provideVariableSet(campaignId).creativeVariables;
        if (!variablesByCreativeActionId) {
            return [];
        }
        const creativeVariables = variablesByCreativeActionId[creativeActionId];
        if (!creativeVariables) {
            return [];
        }
        return _.values(creativeVariables);
    };
    const bindSavingStatus = (variablesToSave, save) => {
        const allVariables = toList(variablesToSave);
        _.each(allVariables, (variable) => variable.startSave());
        save.finally(() => _.each(allVariables, (variable) => {
            variable.completeSave();
        }));
    };
    const getCampaignVariable = (campaignId, name) => {
        return _.find(getCampaignVariables(campaignId), (variable) => variable.name === name);
    };
    const toggleCreativeVariableScope = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const { campaignId, creativeActionId, variableName } = payload;
        const variable = (_a = provideVariableSet(campaignId).creativeVariables[creativeActionId]) === null || _a === void 0 ? void 0 : _a[variableName];
        if (!variable) {
            return;
        }
        const newScope = variable.scope === "CAMPAIGN" ? "CREATIVE" : "CAMPAIGN";
        const update = {
            name: variable.name,
            scope: newScope,
            type: variable.type,
            values: variable.values,
            creative_action_id: variable.creativeActionId,
        };
        if (newScope === "CAMPAIGN") {
            const campaignLevelVariable = getCampaignVariable(campaignId, variableName);
            if (campaignLevelVariable) {
                update.values = campaignLevelVariable.values;
            }
        }
        const updatedVariables = yield variableService.saveVariables(campaignId, {
            campaignVariables: [],
            creativeVariables: {
                [creativeActionId]: [update],
            },
        });
        setVariables(campaignId, updatedVariables);
    });
    const getCreativesUsingCampaignLevelVariable = (campaignId, variableName) => {
        const variablesByCreativeAction = provideVariableSet(campaignId).creativeVariables;
        if (!variablesByCreativeAction) {
            return [];
        }
        const creativeActionIds = [];
        _.each(variablesByCreativeAction, (variables, creativeActionId) => {
            if (variables[variableName] &&
                variables[variableName].scope === "CAMPAIGN") {
                creativeActionIds.push(creativeActionId);
            }
        });
        return creativeActionIds;
    };
    const getConflicts = (campaignId) => {
        const variableSet = provideVariableSet(campaignId);
        return getVariablesWithConflicts(variableSet);
    };
    const hasUnsavedChanges = (campaignId) => {
        const variableSet = provideVariableSet(campaignId);
        const unsavedVariables = getVariablesToSave(variableSet);
        return toList(unsavedVariables).length > 0;
    };
    campaignModuleEventBus.subscribe(EVENT.CAMPAIGN_DRAFT_PUBLISHED, (payload) => {
        fetchVariables(payload.campaignId);
    });
    campaignModuleEventBus.subscribe(EVENT.CAMPAIGN_DRAFT_DISCARDED, (payload) => {
        fetchVariables(payload.campaignId);
    });
    const searchVariables = (campaignId, predicate) => {
        const variables = state[campaignId];
        if (!variables)
            return [];
        return toList(filter(variables, predicate));
    };
    const listAllVariables = (campaignId) => {
        const variables = provideVariableSet(campaignId);
        return toList(variables);
    };
    return {
        state,
        fetchVariables,
        saveVariable,
        saveVariables,
        getVariables,
        getCampaignVariable,
        getCampaignVariables,
        getCreativeVariables,
        getCreativesUsingCampaignLevelVariable,
        hasUnsavedChanges,
        toggleCreativeVariableScope,
        getConflicts,
        searchVariables,
        listAllVariables,
    };
});
