import faro from "src/shared/third-party-integrations/faro";
import { BuiltCampaignControllerActionCreative } from "../BuiltCampaignControllerActionCreative";
import { BuiltCampaignControllerActionGeneric } from "../BuiltCampaignControllerActionGeneric";
import { BuiltCampaignControllerActionEmail } from "../BuiltCampaignControllerActionEmail";
import { BuiltCampaignControllerActionSchedule } from "../BuiltCampaignControllerActionSchedule";
import { BuiltCampaignControllerActionFireAsPerson } from "../BuiltCampaignControllerActionFireAsPerson";
import { BuiltCampaignControllerActionStepSignal } from "../BuiltCampaignControllerActionStepSignal";
import { BuiltCampaignControllerActionEarnReward } from "../BuiltCampaignControllerActionEarnReward";
import { BuiltCampaignControllerActionIncentivize } from "../BuiltCampaignControllerActionIncentivize";
import { BuiltCampaignControllerActionWebhook } from "../BuiltCampaignControllerActionWebhook";
import { BuiltCampaignControllerActionDisplay } from "../BuiltCampaignControllerActionDisplay";
export const isBuiltCampaignControllerTriggerActionCreativeResponse = (response) => response.action_type === "CREATIVE";
const isBuiltCampaignControllerTriggerActionDisplayResponse = (response) => response.action_type === "DISPLAY";
const subclasses = {
    CREATIVE: BuiltCampaignControllerActionCreative,
    DISPLAY: BuiltCampaignControllerActionDisplay,
    EMAIL: BuiltCampaignControllerActionEmail,
    SCHEDULE: BuiltCampaignControllerActionSchedule,
    SIGNAL: BuiltCampaignControllerActionGeneric,
    SIGNAL_V1: BuiltCampaignControllerActionGeneric,
    INCENTIVIZE: BuiltCampaignControllerActionIncentivize,
    SHARE_EVENT: BuiltCampaignControllerActionGeneric,
    FIRE_AS_PERSON: BuiltCampaignControllerActionFireAsPerson,
    DATA_INTELLIGENCE: BuiltCampaignControllerActionGeneric,
    APPROVE: BuiltCampaignControllerActionGeneric,
    DECLINE: BuiltCampaignControllerActionGeneric,
    EARN_REWARD: BuiltCampaignControllerActionEarnReward,
    FULFILL_REWARD: BuiltCampaignControllerActionGeneric,
    REDEEM_REWARD: BuiltCampaignControllerActionGeneric,
    CANCEL_REWARD: BuiltCampaignControllerActionGeneric,
    REVOKE_REWARD: BuiltCampaignControllerActionGeneric,
    STEP_SIGNAL: BuiltCampaignControllerActionStepSignal,
    WEBHOOK: BuiltCampaignControllerActionWebhook,
    EXPRESSION: BuiltCampaignControllerActionGeneric,
    INCENTIVIZE_STATUS_UPDATE: BuiltCampaignControllerActionGeneric,
    CREATE_MEMBERSHIP: BuiltCampaignControllerActionGeneric,
    REMOVE_MEMBERSHIP: BuiltCampaignControllerActionGeneric,
};
function assertNever(x) {
    throw new Error("Unexpected object: " + x);
}
const createInstance = (type, response, context) => {
    const SubClass = subclasses[type];
    return new SubClass(response, context);
};
export const createBuiltCampaignControllerActions = (responses, context) => {
    const steps = [];
    responses.forEach((response) => {
        switch (response.action_type) {
            case "CREATIVE":
                if (isBuiltCampaignControllerTriggerActionCreativeResponse(response)) {
                    steps.push(createInstance(response.action_type, response, context));
                }
                break;
            case "DISPLAY":
                if (isBuiltCampaignControllerTriggerActionDisplayResponse(response)) {
                    steps.push(createInstance(response.action_type, response, context));
                }
                break;
            case "EMAIL":
            case "SCHEDULE":
            case "SIGNAL":
            case "SIGNAL_V1":
            case "INCENTIVIZE":
            case "SHARE_EVENT":
            case "FIRE_AS_PERSON":
            case "DATA_INTELLIGENCE":
            case "APPROVE":
            case "DECLINE":
            case "EARN_REWARD":
            case "FULFILL_REWARD":
            case "REDEEM_REWARD":
            case "CANCEL_REWARD":
            case "REVOKE_REWARD":
            case "STEP_SIGNAL":
            case "WEBHOOK":
            case "EXPRESSION":
            case "INCENTIVIZE_STATUS_UPDATE":
            case "CREATE_MEMBERSHIP":
            case "REMOVE_MEMBERSHIP":
                steps.push(createInstance(response.action_type, response, context));
                break;
            default:
                assertNever(response.action_type);
                faro.pushError(new Error(`Unknown type, ${response.action_type}`));
        }
    });
    return steps;
};
