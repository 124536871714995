import faro from "src/shared/third-party-integrations/faro";
import { BuiltCampaignController } from "../BuiltCampaignController";
import { BuiltCampaignFrontendController } from "../BuiltCampaignFrontendController";
import { BuiltCampaignJourneyEntry } from "../BuiltCampaignJourneyEntry";
const subclasses = {
    CONTROLLER: BuiltCampaignController,
    JOURNEY_ENTRY: BuiltCampaignJourneyEntry,
    FRONTEND_CONTROLLER: BuiltCampaignFrontendController,
};
function assertNever(x) {
    throw new Error("Unexpected object: " + x);
}
const createStepInstance = (type, response, context) => {
    const StepClass = subclasses[type];
    return new StepClass(response, context);
};
const isBuiltCampaignControllerResponse = (response) => {
    return response.type === "CONTROLLER";
};
const isBuiltCampaignJourneyEntryResponse = (response) => {
    return response.type === "JOURNEY_ENTRY";
};
export const isBuiltCampaignFrontendControllerResponse = (response) => {
    return response.type === "FRONTEND_CONTROLLER";
};
export const createBuiltCampaignSteps = (responses, campaignId, campaignVersion) => {
    const steps = [];
    responses.forEach((response) => {
        switch (response.type) {
            case "CONTROLLER":
                if (isBuiltCampaignControllerResponse(response)) {
                    steps.push(createStepInstance("CONTROLLER", response, {
                        campaignId,
                        campaignVersion,
                    }));
                }
                break;
            case "JOURNEY_ENTRY":
                if (isBuiltCampaignJourneyEntryResponse(response)) {
                    steps.push(createStepInstance("JOURNEY_ENTRY", response, {
                        campaignId,
                        campaignVersion,
                    }));
                }
                break;
            case "FRONTEND_CONTROLLER":
                if (isBuiltCampaignFrontendControllerResponse(response)) {
                    steps.push(createStepInstance("FRONTEND_CONTROLLER", response, {
                        campaignId,
                        campaignVersion,
                    }));
                }
                break;
            default:
                assertNever(response.type);
                faro.pushError(new Error(`Unknown type, ${response.type}`));
        }
    });
    return steps;
};
