import _ from "underscore";
import CreativeVariable from "../model/CreativeVariable";
const getBatchResponseByActionId = (response) => {
    const output = {};
    for (const creativeArchiveId in response) {
        const creativeVariableResponses = response[creativeArchiveId];
        for (const creativeVariableResponse of creativeVariableResponses) {
            const creativeActionId = creativeVariableResponse.creative_action_id;
            if (!output[creativeActionId]) {
                output[creativeActionId] = [];
            }
            output[creativeActionId].push(creativeVariableResponse);
        }
    }
    return output;
};
const addSequenceToCreativeVariableResponseSet = (responseSet) => {
    _.each(responseSet, (response, index) => {
        response.sequence = index;
    });
};
const parseCampaignVariableResponses = (campaignId, campaignVersion, creativeVariableResponseSet) => {
    addSequenceToCreativeVariableResponseSet(creativeVariableResponseSet);
    const variables = {};
    _.each(creativeVariableResponseSet, (response) => {
        const variable = new CreativeVariable(response, {
            id: _.uniqueId(),
            context: {
                name: "CAMPAIGN",
                campaignId,
                campaignVersion,
            },
        });
        variables[variable.name] = variable;
    });
    return variables;
};
const parseCreativeVariableResponses = (campaignId, campaignVersion, response) => {
    const output = {};
    const batchVariablesByActionId = getBatchResponseByActionId(response);
    _.each(batchVariablesByActionId, (creativeVariableResponseSet, creativeActionId) => {
        const variablesByName = {};
        addSequenceToCreativeVariableResponseSet(creativeVariableResponseSet);
        _.each(creativeVariableResponseSet, (response) => {
            const variable = new CreativeVariable(response, {
                id: _.uniqueId(),
                context: {
                    name: "CREATIVE",
                    campaignId,
                    campaignVersion,
                },
            });
            variablesByName[variable.name] = variable;
        });
        output[creativeActionId] = variablesByName;
    });
    return output;
};
export const createVariableSet = (campaignId, campaignVersion, campaignVariableResponses, creativeVariableResponses) => {
    return {
        campaignVariables: parseCampaignVariableResponses(campaignId, campaignVersion, campaignVariableResponses),
        creativeVariables: parseCreativeVariableResponses(campaignId, campaignVersion, creativeVariableResponses),
    };
};
