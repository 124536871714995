import { CampaignFrontendAsset } from "./CampaignFrontendAsset";
export class DisplayActionCampaignFrontendAsset extends CampaignFrontendAsset {
    constructor(parameters) {
        super({
            type: "DISPLAY_ACTION",
            step: parameters.step,
            trigger: parameters.trigger,
            component: parameters.component,
            isLegacyCampaign: parameters.isLegacyCampaign,
        });
        this.action = parameters.action;
    }
    getActionId() {
        return this.action.action_id;
    }
    getAssetHandle() {
        return this.getActionId();
    }
}
