import { useCampaignVariants } from "src/shared/store/campaign-built/composables/useCampaignVariants";
import { computed, reactive, toValue, watch } from "vue";
const workingVariants = reactive({});
const getOrCreateWorkingVariants = (campaignId) => {
    if (!workingVariants[campaignId]) {
        workingVariants[campaignId] = new Set();
        const { defaultVariant } = useCampaignVariants(campaignId);
        watch(defaultVariant, (variant) => {
            if (variant) {
                workingVariants[campaignId].add(variant);
            }
        }, {
            immediate: true,
        });
    }
    return workingVariants[campaignId];
};
export const useCampaignWorkingVariants = (campaignId) => {
    const workingVariants = computed(() => {
        const campaignIdValue = toValue(campaignId);
        if (!campaignIdValue) {
            return [];
        }
        const workingVariants = getOrCreateWorkingVariants(campaignIdValue);
        return Array.from(workingVariants);
    });
    const isWorkingVariantActive = (variant) => {
        const campaignIdValue = toValue(campaignId);
        if (!campaignIdValue) {
            return false;
        }
        const workingVariants = getOrCreateWorkingVariants(campaignIdValue);
        return workingVariants.has(variant);
    };
    const activateWorkingVariant = (variant) => {
        const campaignIdValue = toValue(campaignId);
        if (!campaignIdValue) {
            return;
        }
        const workingVariants = getOrCreateWorkingVariants(campaignIdValue);
        workingVariants.add(variant);
    };
    const deactivateWorkingVariant = (variant) => {
        const campaignIdValue = toValue(campaignId);
        if (!campaignIdValue) {
            return;
        }
        const workingVariants = getOrCreateWorkingVariants(campaignIdValue);
        workingVariants.delete(variant);
    };
    const toggleWorkingVariant = (variant) => {
        if (isWorkingVariantActive(variant)) {
            deactivateWorkingVariant(variant);
        }
        else {
            activateWorkingVariant(variant);
        }
    };
    return {
        workingVariants,
        toggleWorkingVariant,
        isWorkingVariantActive,
        activateWorkingVariant,
        deactivateWorkingVariant,
    };
};
