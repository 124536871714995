import { computed, watch } from "vue";
import { useCreativeVariableStore } from "../../creative-variable";
import { useBuiltCampaign } from "./useBuiltCampaign";
import { useLocaleConfiguration } from "../../creative-variable/locales";
export const useCampaignVariants = (campaignId) => {
    const { builtCampaign } = useBuiltCampaign(campaignId);
    const creativeVariableStore = useCreativeVariableStore();
    watch(() => { var _a; return (_a = builtCampaign.value) === null || _a === void 0 ? void 0 : _a.hasLegacyCreatives; }, (hasLegacyCreatives) => {
        if (builtCampaign.value && hasLegacyCreatives) {
            creativeVariableStore.fetchVariables(builtCampaign.value.campaign_id);
        }
    });
    const legacyLocaleConfiguration = useLocaleConfiguration(campaignId);
    const defaultVariant = computed(() => {
        if (!builtCampaign.value) {
            return null;
        }
        if (builtCampaign.value.hasLegacyCreatives) {
            return legacyLocaleConfiguration.value.defaultLocale;
        }
        else {
            return builtCampaign.value.variants[0] || null;
        }
    });
    const variants = computed(() => {
        if (!builtCampaign.value) {
            return [];
        }
        if (builtCampaign.value.hasLegacyCreatives) {
            return legacyLocaleConfiguration.value.locales;
        }
        else {
            return builtCampaign.value.variants;
        }
    });
    return {
        defaultVariant,
        variants,
    };
};
