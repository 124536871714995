import _ from "underscore";
import CreativeVariable from "./CreativeVariable";
const hash = (variable) => {
    const parts = [];
    _.each(variable.values, (value, locale) => {
        parts.push(value);
        parts.push(locale);
    });
    return _.chain(parts).sort().join("").value();
};
class CreativeVariableMutable {
    constructor(variable) {
        this.source = variable;
        this.isSaving = false;
        this.cloneValues();
        this.sourceHashWhenBecameDirty = null;
        this.creativeActionId = variable.creative_action_id;
    }
    clone() {
        return new CreativeVariableMutable(this.source);
    }
    get name() {
        return this.source.name;
    }
    get context() {
        return this.source.context;
    }
    get scope() {
        return this.source.scope;
    }
    get type() {
        return this.source.type;
    }
    get sequence() {
        return this.source.sequence;
    }
    get visible() {
        return this.source.visible;
    }
    get label() {
        return this.source.label;
    }
    cloneValues() {
        this.values = Object.assign({}, this.source.values);
    }
    getLocales() {
        return _.keys(this.values);
    }
    getWorkingLocaleValuePair(locale, defaultLocale = CreativeVariable.DEFAULT_LOCALE) {
        const locales = this.getLocales();
        let workingLocale = CreativeVariable.DEFAULT_LOCALE;
        if (_.contains(locales, defaultLocale)) {
            workingLocale = defaultLocale;
        }
        if (locale !== null && _.contains(locales, locale)) {
            workingLocale = locale;
        }
        const outputValue = _.has(this.values, workingLocale)
            ? this.values[workingLocale]
            : "";
        const output = {
            locale: workingLocale,
            value: outputValue,
        };
        return output;
    }
    getValue(locale) {
        const workingLocaleValuePair = this.getWorkingLocaleValuePair(locale);
        return workingLocaleValuePair.value;
    }
    updateValue(locale, value, fileName) {
        this.values[locale] = value;
        this.fileName = fileName;
        if (!this.isDirty()) {
            this.sourceHashWhenBecameDirty = hash(this.source);
        }
    }
    updateSource(variable) {
        this.source = variable;
        if (!this.isDirty()) {
            this.cloneValues();
        }
    }
    isDirty() {
        return this.sourceHashWhenBecameDirty !== null;
    }
    hasConflicts() {
        if (this.isDirty()) {
            return this.sourceHashWhenBecameDirty !== hash(this.source);
        }
        return false;
    }
    resolveSourceConflict(keep) {
        this.sourceHashWhenBecameDirty = hash(this.source);
        if (keep === "KEEP_SOURCE") {
            this.cloneValues();
        }
    }
    startSave() {
        this.isSaving = true;
    }
    completeSave() {
        this.isSaving = false;
        this.sourceHashWhenBecameDirty = null;
    }
}
CreativeVariableMutable.DEFAULT_LOCALE = "en";
export default CreativeVariableMutable;
