import _ from "underscore";
import { computed, toValue } from "vue";
import { useCreativeVariableStore } from ".";
import CreativeVariable from "./model/CreativeVariable";
const DEFAULT_LOCALE_VARIABLE_NAME = "defaultLocale";
const ENABLED_LOCALES_VARIABLE_NAME = "enabledLocales";
const parseDefaultLocale = (variable) => {
    return (variable.getValue(CreativeVariable.DEFAULT_LOCALE) || null);
};
const parseEnabledLocales = (variable) => {
    const value = variable.getValue(CreativeVariable.DEFAULT_LOCALE);
    if (value && typeof value === "string") {
        return value.split(/\s*,\s*/);
    }
    return [];
};
const calculateAvailableLocales = (enabledLocales, allLocales) => {
    if (enabledLocales && enabledLocales.length) {
        return enabledLocales;
    }
    if (allLocales && allLocales.length) {
        return allLocales;
    }
    return [CreativeVariable.DEFAULT_LOCALE];
};
const parseLocaleDataFromVariableSet = (variables) => {
    const allLocales = {};
    const output = {
        defaultLocale: null,
        allLocales: [],
        enabledLocales: [],
    };
    _.each(variables.campaignVariables, (variable) => {
        if (variable.name === DEFAULT_LOCALE_VARIABLE_NAME) {
            output.defaultLocale = parseDefaultLocale(variable);
        }
        if (variable.name === ENABLED_LOCALES_VARIABLE_NAME) {
            output.enabledLocales = parseEnabledLocales(variable);
        }
        _.extend(allLocales, variable.values);
    });
    _.each(variables.creativeVariables, (creativeVariable) => {
        _.each(creativeVariable, (variable) => {
            _.extend(allLocales, variable.values);
        });
    });
    return output;
};
export const useLocaleConfiguration = (campaignId) => {
    const creativeVariableStore = useCreativeVariableStore();
    return computed(() => {
        const campaignIdValue = toValue(campaignId);
        if (!campaignIdValue) {
            return {
                defaultLocale: CreativeVariable.DEFAULT_LOCALE,
                locales: [],
            };
        }
        const variables = creativeVariableStore.getVariables(campaignIdValue);
        const localeData = parseLocaleDataFromVariableSet(variables);
        const locales = calculateAvailableLocales(localeData.enabledLocales, localeData.allLocales);
        return {
            defaultLocale: localeData.defaultLocale || CreativeVariable.DEFAULT_LOCALE,
            locales,
        };
    });
};
