import Strings from "src/shared/Strings";
const getHumanReadableName = (parameters) => {
    if (parameters.component && !parameters.isLegacyCampaign) {
        return parameters.component.name;
    }
    const inputEventName = parameters.trigger.getInputEventName();
    if (inputEventName) {
        return inputEventName;
    }
    return parameters.trigger.trigger_name;
};
export class CampaignFrontendAsset {
    constructor(parameters) {
        this.type = parameters.type;
        this.step = parameters.step;
        this.trigger = parameters.trigger;
        this.component = parameters.component;
        this.isLegacyCampaign = parameters.isLegacyCampaign;
    }
    getCampaignId() {
        return this.step.context.campaignId;
    }
    getStepId() {
        return this.step.id;
    }
    getTriggerId() {
        return this.trigger.trigger_id;
    }
    getComponentId() {
        var _a;
        return (_a = this.component) === null || _a === void 0 ? void 0 : _a.id;
    }
    getTriggerName() {
        return this.trigger.trigger_name;
    }
    getInputEventName() {
        return this.trigger.getInputEventName();
    }
    getCampaignVersion() {
        return this.step.context.campaignVersion;
    }
    getDisplayName() {
        const humanReadableName = getHumanReadableName({
            trigger: this.trigger,
            component: this.component,
            isLegacyCampaign: this.isLegacyCampaign,
        });
        const handleWithCategoryPrefixRemoved = humanReadableName.replace(new RegExp(`^${this.getCategory()}\\s*`, "i"), "");
        return Strings.capitalize(handleWithCategoryPrefixRemoved);
    }
    getCategory() {
        return this.step.category;
    }
    isEnabled() {
        return this.step.enabled;
    }
    isLegacy() {
        return false;
    }
}
