import _ from "underscore";
const convertCampaignVariableUpdateToCreativeArchiveVariableUpdates = (campaignVariableUpdate, creativeActionIdsByCampaignLevelVariableName) => {
    const creativesToUpdate = creativeActionIdsByCampaignLevelVariableName[campaignVariableUpdate.name] || [];
    return _.map(creativesToUpdate, (creativeId) => {
        return {
            creative_action_id: creativeId,
            name: campaignVariableUpdate.name,
            values: campaignVariableUpdate.values,
        };
    });
};
export const mapCampaignVariableUpdateRequestsToCreativeArchiveVariableUpdates = (campaignVariableUpdates, creativeActionIdsByCampaignLevelVariableName) => {
    return _.chain(campaignVariableUpdates)
        .map((campaignVariableUpdate) => convertCampaignVariableUpdateToCreativeArchiveVariableUpdates(campaignVariableUpdate, creativeActionIdsByCampaignLevelVariableName))
        .flatten()
        .value();
};
