import { CreativeActionCampaignFrontendAsset } from "./CreativeActionCampaignFrontendAsset";
import { DisplayActionCampaignFrontendAsset } from "./DisplayActionCampaignFrontendAsset";
import { isBuiltCampaignFrontendController, } from "../BuiltCampaignFrontendController";
import { isBuiltCampaignControllerTriggerEvent, } from "../BuiltCampaignControllerTriggerEvent";
const getComponent = (action, campaign) => {
    var _a;
    const rootComponentId = (_a = campaign.components.find((component) => component.name === "root")) === null || _a === void 0 ? void 0 : _a.id;
    const firstReferencedComponentId = action.component_ids[0];
    if (!firstReferencedComponentId) {
        return undefined;
    }
    return campaign.components.find((component) => component.id === firstReferencedComponentId &&
        component.id !== rootComponentId);
};
const getEventTrigger = (step) => {
    if (!step) {
        return undefined;
    }
    const trigger = step.triggers.find((trigger) => isBuiltCampaignControllerTriggerEvent(trigger));
    if (!trigger) {
        return undefined;
    }
    if (isBuiltCampaignControllerTriggerEvent(trigger)) {
        return trigger;
    }
    return undefined;
};
export const getCampaignFrontendAssets = (campaign) => {
    const assetsMap = new Map();
    campaign.getCreativeActions().forEach((action) => {
        const step = campaign.getStep(action.context.stepId);
        const component = getComponent(action, campaign);
        if (step && isBuiltCampaignFrontendController(step)) {
            const trigger = getEventTrigger(step);
            if (!trigger) {
                return;
            }
            const asset = new CreativeActionCampaignFrontendAsset({
                step,
                trigger,
                action,
                component,
                isLegacyCampaign: campaign.isLegacyCampaign(),
            });
            assetsMap.set(asset.getAssetHandle(), asset);
        }
    });
    campaign.getDisplayActions().forEach((action) => {
        const step = campaign.getStep(action.context.stepId);
        const component = getComponent(action, campaign);
        if (step && isBuiltCampaignFrontendController(step)) {
            const trigger = getEventTrigger(step);
            if (!trigger) {
                return;
            }
            const asset = new DisplayActionCampaignFrontendAsset({
                step,
                trigger,
                action,
                component,
                isLegacyCampaign: campaign.isLegacyCampaign(),
            });
            assetsMap.set(asset.getAssetHandle(), asset);
        }
    });
    return Array.from(assetsMap.values());
};
