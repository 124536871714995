import _ from "underscore";
import CreativeVariableEndpoints from "src/shared/api/creative/CreativeVariableEndpoints";
import creativeVariableOperationQueue from "./creative-variable-operation-queue";
import { mapCampaignVariableUpdateRequestsToCreativeArchiveVariableUpdates } from "./mappers";
const lookupCreativeActionIdsByCampaignLevelVariableName = (campaignId) => {
    return new Promise((resolve, reject) => {
        const output = {};
        CreativeVariableEndpoints.batchGetVariables(campaignId)
            .done((response) => {
            _.each(response, (creativeVariableResponseSet) => {
                const campaignScopedVariables = _.filter(creativeVariableResponseSet, (creativeVariableResponse) => creativeVariableResponse.scope === "CAMPAIGN");
                _.each(campaignScopedVariables, (campaignScopedVariable) => {
                    const variableName = campaignScopedVariable.name;
                    output[variableName] = output[variableName] || [];
                    output[variableName].push(campaignScopedVariable.creative_action_id);
                });
            });
            resolve(output);
        })
            .fail(() => reject());
    });
};
export const batchUpdateCreativeVariables = (campaignId, creativeVariableUpdateRequests) => {
    return new Promise((resolve, reject) => {
        creativeVariableOperationQueue.getQueue(campaignId).push((done) => {
            CreativeVariableEndpoints.batchUpdateCreativeVariables(campaignId, creativeVariableUpdateRequests)
                .then(() => resolve())
                .fail(() => reject())
                .always(() => done());
        });
    });
};
export const replaceLocaleImage = (campaignId, creativeId, variableName, locale, image) => {
    return new Promise((resolve, reject) => {
        creativeVariableOperationQueue.getQueue(campaignId).push((done) => {
            CreativeVariableEndpoints.replaceLocaleImagePost(campaignId, creativeId, variableName, locale, image)
                .then(() => resolve())
                .fail(() => reject())
                .always(() => done());
        });
    });
};
export const saveCampaignVariableUpdates = (campaignId, campaignVariableUpdateRequests) => {
    return new Promise((resolve, reject) => {
        creativeVariableOperationQueue.getQueue(campaignId).push((done) => {
            lookupCreativeActionIdsByCampaignLevelVariableName(campaignId)
                .then((creativeIdsByCampaignLevelVariableName) => {
                const creativeArchiveVariableUpdates = mapCampaignVariableUpdateRequestsToCreativeArchiveVariableUpdates(campaignVariableUpdateRequests, creativeIdsByCampaignLevelVariableName);
                batchUpdateCreativeVariables(campaignId, creativeArchiveVariableUpdates)
                    .then(() => resolve(null))
                    .catch(() => reject())
                    .finally(() => done());
            })
                .catch(() => reject())
                .finally(() => done());
        });
    });
};
export const replaceCampaignLocaleImage = (campaignId, campaignLocaleImageUpdate) => {
    return new Promise((resolve, reject) => {
        creativeVariableOperationQueue.getQueue(campaignId).push((done) => {
            CreativeVariableEndpoints.replaceCampaignLocaleImagePost(campaignId, campaignLocaleImageUpdate.name, campaignLocaleImageUpdate.locale, campaignLocaleImageUpdate.image)
                .then(() => resolve(null))
                .fail(() => reject())
                .always(() => done());
        });
    });
};
