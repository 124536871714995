import $ from "jquery";
const getCampaignVariables = (campaignId, data = {}) => $.ajax({
    method: "GET",
    url: `api:///v2/campaigns/${campaignId}/variables`,
    data,
});
const getCreativeVariables = (campaignId, creativeArchiveId, data = {}) => $.ajax({
    method: "GET",
    url: `api:///v2/campaigns/${campaignId}/creatives/${creativeArchiveId}/variables`,
    data,
});
const batchGetVariables = (campaignId, data = {}) => $.ajax({
    method: "GET",
    url: `api:///v2/campaigns/${campaignId}/creative/variable/batch`,
    data,
});
const batchUpdateCreativeVariables = (campaignId, creativeVariableUpdateRequests) => $.ajax({
    method: "PUT",
    url: `api:///v2/campaigns/${campaignId}/creative/variable/batch`,
    contentType: "application/json",
    data: JSON.stringify(creativeVariableUpdateRequests),
});
const replaceLocaleImagePost = (campaignId, creativeArchiveId, variableName, locale, value) => $.ajax({
    url: `api:///v2/campaigns/${campaignId}/creatives/${creativeArchiveId}/variables/${variableName}/${locale}`,
    data: value,
    cache: false,
    contentType: false,
    processData: false,
    type: "POST",
});
const replaceCampaignLocaleImagePost = (campaignId, variableName, locale, value) => $.ajax({
    url: `api:///v2/campaigns/${campaignId}/variables/${variableName}/${locale}`,
    data: value,
    cache: false,
    contentType: false,
    processData: false,
    type: "POST",
});
export default {
    getCampaignVariables,
    getCreativeVariables,
    batchGetVariables,
    batchUpdateCreativeVariables,
    replaceLocaleImagePost,
    replaceCampaignLocaleImagePost,
};
