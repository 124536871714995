var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { computed, toValue } from "vue";
import { useBuiltComponentStore } from "..";
import { AsyncPrimer } from "../../common/async-primer";
const asyncPrimer = new AsyncPrimer((key) => key.campaignId, ({ campaignId }) => __awaiter(void 0, void 0, void 0, function* () {
    const builtComponentStore = useBuiltComponentStore();
    yield builtComponentStore.fetchCampaignBuiltComponents(campaignId);
}));
export const useCampaignBuiltComponents = (campaignId) => {
    const builtComponentStore = useBuiltComponentStore();
    const primer = asyncPrimer.prime(() => {
        const campaignIdValue = toValue(campaignId);
        if (!campaignIdValue) {
            return undefined;
        }
        return { campaignId: campaignIdValue };
    });
    const builtComponents = computed(() => {
        const campaignIdValue = toValue(campaignId);
        if (!campaignIdValue) {
            return [];
        }
        return builtComponentStore.getCampaignBuiltComponents(campaignIdValue);
    });
    return {
        primer,
        builtComponents,
    };
};
